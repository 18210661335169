import {
  useContext,
  Show,
  createMemo,
  createResource,
  Switch,
  Match,
  createEffect,
  createSignal,
} from "solid-js";
import { A, useNavigate } from "@solidjs/router";
import { produce } from "solid-js/store";
import { PT } from "~/utils/products";
import {
  useItemOrderContext,
  useSiteContext,
  useSessionContext,
  useErrorContext,
} from "~/utils/contexts";
import { InfoBox } from "~/components/utility";
import { BaseLoader } from "~/components/utility";
import { EventType } from "@solid-primitives/analytics";
import {
  stockThreshold,
  ThresholdStatus,
  ThresholdCheckout,
} from "~/utils/threshold";
import { SelectBox } from "~/components/inputs";
import { PLANTS } from "~/services/roma-api/products/types";
import type {
  PlantCodeKeys,
  ProductPriceResponse,
} from "~/services/roma-api/products/types";

import { StackInventorySelector } from "./StackInventorySelector";

//! import { facilityMapping } from "~/utils/misc";
//! import StackInventorySelector from "./StackInventorySelector";

const productTypesArray = [
  [PT.LENGTH, "Length"],
  [PT.CONTRACT, "Contract"],
  [PT.CHOP, "Chop"],
  [PT.JOIN, "Join"],
  [PT.BOX, "Box"],
  [PT.RAIL, "Rail"],
  [PT.PHOTOFRAME, "Photo Frame"],
  [PT.GALLERYFRAME, "Gallery Frame"],
  [PT.MIRROR, "Mirror"],
  [PT.CORNERSAMPLE, "Corner Sample"],
  ["stackJoin2", "Stacked Join Frame (2 Mouldings)"],
  ["stackJoin3", "Stacked Join Frame (3 Mouldings)"],
  ["stackChop2", "Stacked Chop Frame (2 Mouldings)"],
  ["stackChop3", "Stacked Chop Frame (3 Mouldings)"],
  ["stackMirror2", "Stacked Mirror Frame (2 Mouldings)"],
  ["stackMirror3", "Stacked Mirror Frame (3 Mouldings)"],
];

type ExtendedPT =
  | `${PT}`
  | "stackJoin2"
  | "stackJoin3"
  | "stackChop2"
  | "stackChop3"
  | "stackMirror2"
  | "stackMirror3";

export const productTypesDictionary: Record<string, string> =
  productTypesArray.reduce((memo, item) => {
    memo[item[0]] = item[1];
    return memo;
  }, {} as Record<string, string>);

// TODO - eliminate facilityMapping and use PLANTS
export const facilityMapping: Record<string, string> = {
  BP01: "CAN",
  BP03: "LA",
  BP04: "NJ",
  BP05: "AT",
};

export const reverseFacilityMapping: Record<string, string> = {
  CAN: "BP01",
  LA: "BP03",
  NJ: "BP04",
  AT: "BP05",
};

export const TypeSelector = () => {
  const { session, isPartner } = useSessionContext();
  const { track } = useSiteContext();
  const { orderData, setOrderData } = useItemOrderContext();
  const { addError } = useErrorContext();
  const navigate = useNavigate();

  const [blockFromProceeding, setBlockFromProceeding] = createSignal(false);

  const [productInfo] = createResource(
    () =>
      isPartner() && orderData.selected.moulding
        ? [orderData.selected.moulding, orderData.availableAs]
        : false,
    async ([sku, availableList]) => {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_ROMA_API}/products/${sku}/prices`,
          {
            headers: {
              Authorization: `Bearer ${session()?.token}`,
            },
          }
        );

        if (response.ok) {
          const data: ProductPriceResponse = await response.json();
          const info = {
            Pricing: {} as Record<
              string,
              | ProductPriceResponse["Pricing"][keyof ProductPriceResponse["Pricing"]]
              | boolean
            >, // an attempt to relieve some of the type errors below..
            Inventory: [] as ItemInventory[],
            status: "",
          };

          // ! PRICING
          info.Pricing = data.Pricing;

          // ! wtf is going on here ??
          // ? Purpose of this wierdness:
          // * The type selector dropdown does not display prices for JOIN or CHOP (CAD Partners)
          // * So this is just to provide a truthy value so it will be included in the dropdown
          // * menu. This also manually inserts stackJoin/stackChops into the menu, as these are
          // * not provided in the availableAs array - we include if JOIN/CHOP are.
          // * !! Very messy - should be refactored.

          if (availableList?.includes(PT.JOIN)) info.Pricing.join = true;

          if (availableList?.includes(PT.CHOP) && data.Currency === "CAD")
            info.Pricing.chop = true;

          if (availableList?.includes(PT.JOIN)) {
            info.Pricing.stackJoin2 = true;
            info.Pricing.stackJoin3 = true;
          }
          if (availableList?.includes(PT.CHOP)) {
            info.Pricing.stackChop2 = true;
            info.Pricing.stackChop3 = true;
          }
          // logic to add stackMirror2/3 would be included here

          // ! INVENTORY:

          if (
            !orderData.editProduct ||
            (orderData.editProduct &&
              orderData.selected.shipsFrom?.length === 0)
            // ? length check is to determine whether the plant is an empty string(defaultPlant) or not
          ) {
            setOrderData(
              produce((store) => {
                store.selected.shipsFrom = data.Plant;
                store.selected.shipsFromStockLevel = Math.round(
                  data.Inventory[data.Plant]
                );
              })
            );
          } else if (orderData.editProduct) {
            setOrderData(
              "selected",
              "shipsFromStockLevel",
              data.Inventory[orderData.selected.shipsFrom! as PlantCodeKeys]
            );
          }

          info.Inventory = Object.entries(data.Inventory).reduce(
            (memo: any[], [name, quantity]: [string, number]) => {
              if (facilityMapping[name]) {
                memo.push({
                  name: facilityMapping[name],
                  plant: name,
                  quantity: Math.round(quantity),
                });
              }
              return memo;
            },
            []
          );

          setOrderData(
            produce((store) => {
              store.defaultShipsFrom = data.Plant;
              store.shipsFromLength = info.Inventory.length;
            })
          );

          info.status = "success";

          return info;
        } else if (!response.ok) {
          const resp = await response.json();

          const errObj: Record<string, string> = { status: "error" };

          if (resp.Code) {
            errObj.Code = resp.Code;
          }
          addError(resp, {
            showDetails: true,
            title: "An error occurred",
            message:
              "An error occurred while retrieving pricing details for this moulding. Please try again. If the error persists, kindly contact customer support. We apologize for the inconvenience.",
            severity: "critical",
            actions: [
              {
                label: "Contact Support",
                action: async () => {
                  navigate("/support");
                },
              },
            ],
          });
          return errObj;
        }
      } catch (err) {
        console.log(err);
        return { status: "error" };
      }
    },
    {
      initialValue: { status: "loading" },
      ssrLoadFrom: "initial",
    }
  );

  createEffect(() => {
    // ? Whats going on here ?
    // * Seems like some pretty arbitrary setting of shipsFromPlant, but I think this was
    // * to resolve some bugginess when an existing cart item is being edited..to be
    // * reviewed and refactored

    if (
      orderData.selected.shipsFrom &&
      orderData.defaultShipsFrom !== orderData.selected.shipsFrom
    ) {
      setOrderData("selected", "shipsFromPlant", orderData.selected.shipsFrom);
    } else if (orderData.defaultShipsFrom == orderData.selected.shipsFrom) {
      setOrderData("selected", "shipsFromPlant", "");
    }
  });

  const inventoryDictionary = createMemo(() => {
    if (!productInfo.latest?.Inventory) return {};
    const obj = (productInfo()?.Inventory as ItemInventory[]).reduce(
      (memo, item) => ({ ...memo, [item.plant]: item.quantity }),
      {} as Record<string, number>
    );
    return obj;
  });

  const typeOptions = createMemo(() => {
    if (!productInfo.latest?.Pricing) return [];

    // returns an object with 'label' and optional 'discountedFrom', to be
    // used on the right side of the select label (left side is product type)
    const productTypeLabel = (type: string, item: any) => {
      const price = () => item.Amount?.toFixed(2);
      const discount = () => (item.Discount ? Math.abs(item?.Discount) : 0);
      switch (true) {
        case type === PT.LENGTH && !!item.Amount:
        case type === PT.RAIL && !!item.Amount:
        case type === PT.CHOP && !!item.Amount:
          return {
            label: `$${price()}/ft`,
            discountedFrom:
              discount() > 0
                ? `$${(discount() + item.Amount).toFixed(2)}`
                : undefined,
          };
        case type === PT.BOX:
          return {
            label: `$${price()} (Box of ${item.Quantity}')`,
          };
        case type === PT.PHOTOFRAME || type === PT.GALLERYFRAME:
          return {
            label: `from $${price()}`,
            discountedFrom:
              discount() > 0
                ? `$${(discount() + item.Amount).toFixed(2)}`
                : undefined,
          };
        case type === PT.CORNERSAMPLE:
          return {
            label: `$${price()}`,
          };
        case type === PT.CONTRACT:
          return {
            label: `$${price()}/ft`,
          };
        default:
          return {
            label: "Select to calculate price",
          };
      }
    };

    const arr: {
      value: string;
      leftLabel: string;
      rightLabel: { label: string; discountedFrom?: string };
    }[] = [];

    // ? Some wierd inference having with .Pricing (possibly a string?) - ts expect error, refactor eventually

    productTypesArray.forEach((item) => {
      // @ts-expect-error
      if (productInfo.latest?.Pricing && productInfo.latest.Pricing[item[0]]) {
        arr.push({
          value: item[0],
          leftLabel: item[1],
          rightLabel: productTypeLabel(
            item[0],
            // @ts-expect-error
            productInfo.latest.Pricing[item[0]]
          ),
        });
      }
    });

    return arr;
  });

  const computedStockThreshold = createMemo(() => {
    if (!productInfo() && !productInfo()?.Inventory) return;

    if ((productInfo()?.Inventory as ItemInventory[])?.length > 1) {
      let status = "block";
      if (productInfo()?.Inventory && Array.isArray(productInfo()?.Inventory)) {
        const arr = productInfo()?.Inventory ?? [];

        for (const plant of arr as ItemInventory[]) {
          if (
            stockThreshold(
              plant.quantity,
              orderData.currentProduct!.Category,
              orderData.isDiscontinued
            ).checkout !== ThresholdCheckout.block
          ) {
            status = "allow";
            break;
          }
        }
      }

      if (status === "block") {
        setBlockFromProceeding(true);
        setOrderData("selected", "productType", undefined);
      } else {
        setBlockFromProceeding(false);
      }
    }

    return stockThreshold(
      orderData.selected.shipsFromStockLevel!,
      orderData.currentProduct!.Category,
      orderData.isDiscontinued
    );
  });

  return (
    <>
      <Show
        when={productInfo() && productInfo()?.Inventory}
        fallback={
          <Show when={productInfo()?.status === "error"}>
            <Switch
              fallback={
                <InfoBox type="error">
                  An error occurred while attempting to retrieve pricing
                  information for this moulding - please call customer care at{" "}
                  <a
                    href="tel:18002632322"
                    rel="nofollow"
                    class="text-roma-blue"
                  >
                    1-800-263-2322
                  </a>{" "}
                  to proceed with this order. We apologize for the
                  inconvenience.
                </InfoBox>
              }
            >
              {/* When this is refactored, this should be handled with an error boundary and thrown error.. */}
              {/* @ts-ignore */}
              <Match when={productInfo()?.Code == "CREDIT_BLOCK"}>
                <InfoBox type="error">
                  Unfortunately we are unable to display details for this
                  product because your Roma account has been blocked. Kindly
                  contact{" "}
                  <A href="./support" class="text-roma-blue">
                    customer service
                  </A>{" "}
                  for additional support.
                </InfoBox>
              </Match>
            </Switch>
          </Show>
        }
      >
        <Show when={orderData.selected?.productType?.includes("stack")}>
          {/* TODO */}
          <StackInventorySelector />
          {/* <p class="text-orange-500 border-2 border-orange-500">StackInventorySelector</p> */}
        </Show>
        <Show
          when={
            ![PT.GALLERYFRAME, PT.PHOTOFRAME, PT.CORNERSAMPLE].includes(
              orderData.selected.productType as PT
            ) && !orderData.selected?.productType?.includes(PT.STACK)
          }
        >
          <SelectBox
            triggerClass="rounded-sm"
            label="Ships From"
            defaultValue={orderData.selected.shipsFrom}
            value={{ value: orderData.selected.shipsFrom }}
            options={(productInfo.latest?.Inventory as ItemInventory[])?.map(
              (item, _, arr) => {
                return {
                  label: (
                    <div>
                      <span class="w-[30px] inline-block">{item.name}</span>
                      <span class="text-center mx-3">&mdash;</span>
                      <span>
                        {/* Tariff Logic - Block the option for US customers to ship from CA. Remove when resolved */}
                        {item.name === "CAN" &&
                        productInfo.latest?.Inventory.length !== 1
                          ? "Unavailable"
                          : stockThreshold(
                              item.quantity,
                              orderData.currentProduct?.Category!,
                              orderData.isDiscontinued
                            ).dropdownContent}
                        {item.plant === orderData.defaultShipsFrom &&
                        arr.length !== 1 ? (
                          <span> (Default)</span>
                        ) : null}
                      </span>
                    </div>
                  ),
                  value: item.plant,
                  disabled:
                    //! Tariff Logic - Block the option for US customers to ship from CA. Remove when resolved
                    item.name === "CAN" &&
                    productInfo.latest?.Inventory.length !== 1,
                };
              }
            )}
            disabled={productInfo.latest?.Inventory.length === 1}
            loading={productInfo.loading}
            hideIndicator={productInfo.latest?.Inventory.length === 1}
            onChange={(option) => {
              setOrderData("selected", "shipsFrom", option.value as string);
              setOrderData(
                "selected",
                "shipsFromStockLevel",
                inventoryDictionary()[option.value]
              );
            }}
          />
        </Show>
        {/* STOCK LEVEL WARNING */}
        <Show
          when={
            !!computedStockThreshold() &&
            [ThresholdStatus.zeroStock].includes(
              computedStockThreshold()!.status
            ) &&
            ![PT.PHOTOFRAME, PT.GALLERYFRAME, PT.CORNERSAMPLE].includes(
              orderData.selected.productType as PT
            )
          }
        >
          <Switch>
            <Match when={blockFromProceeding()}>
              <InfoBox type="error">
                There is no available stock for this item. Please select another
                moulding to continue.
              </InfoBox>
            </Match>
            <Match when={!orderData.isDiscontinued}>
              <InfoBox>
                <Show
                  when={orderData.shipsFromLength === 1}
                  fallback={
                    <p class="text-roma-dark-grey">
                      In order to proceed, please select an alternative
                      Distribution Center or continue from your selected DC and
                      your item will be placed on back order.
                    </p>
                  }
                >
                  <p class="text-roma-dark-grey">
                    In order to proceed, kindly note, your item will be placed
                    on back order.
                  </p>
                </Show>
              </InfoBox>
            </Match>
            <Match
              when={
                orderData.isDiscontinued &&
                computedStockThreshold()?.status === ThresholdStatus.zeroStock
              }
            >
              <InfoBox>
                <Show
                  when={orderData.shipsFromLength === 1}
                  fallback={
                    <p class="text-roma-dark-grey">
                      In order to proceed, please select an alternative
                      Distribution Center.
                    </p>
                  }
                >
                  <p class="text-roma-dark-grey">
                    Unfortunately this moulding is sold out. Please select an
                    alternative moulding to proceed.
                  </p>
                </Show>
              </InfoBox>
            </Match>
          </Switch>
        </Show>

        {/* PLANT CHANGE WARNING */}
        <Show
          when={
            orderData.selected.shipsFrom !== orderData.defaultShipsFrom &&
            ![PT.CORNERSAMPLE, PT.GALLERYFRAME, PT.PHOTOFRAME].includes(
              orderData.selected.productType as PT
            ) &&
            !blockFromProceeding()
          }
        >
          <InfoBox>
            <span class="text-roma-dark-grey">
              Additional freight charges may apply by selecting this
              distribution center.
            </span>
          </InfoBox>
        </Show>
        <Show when={orderData.availableAs && productInfo()}>
          <Switch>
            <Match when={productInfo()?.status == "success"}>
              <SelectBox
                label="Product Type"
                placeholder="Please select an option"
                triggerClass="rounded-sm"
                listboxClass="max-h-[40vh]"
                variantType="justify-between"
                disabled={blockFromProceeding()}
                // @ts-expect-error
                options={typeOptions().map((item) => {
                  return {
                    value: item.value,
                    leftLabel: item.leftLabel,
                    rightLabel: (
                      <>
                        {item.rightLabel.discountedFrom && (
                          <span class="text-roma-medium-grey line-through mr-2">
                            {item.rightLabel.discountedFrom}
                          </span>
                        )}
                        <span>{item.rightLabel.label}</span>
                      </>
                    ),
                    // label: (
                    //   <VariantLabel
                    //     left={item.leftLabel}
                    //     right={
                    //       <>
                    //         {item.rightLabel.discountedFrom && (
                    //           <span class="text-roma-medium-grey line-through mr-2">
                    //             {item.rightLabel.discountedFrom}
                    //           </span>
                    //         )}
                    //         <span>{item.rightLabel.label}</span>
                    //       </>
                    //     }
                    //   />
                    // ),
                  };
                })}
                defaultValue={orderData.selected.productType}
                value={
                  orderData.selected.productType
                    ? { value: orderData.selected.productType }
                    : undefined
                }
                onChange={(option) => {
                  const val = option.value as ExtendedPT;

                  setOrderData("selected", "productType", val);
                  track(EventType.Event, {
                    category: "order",
                    action: "product_type",
                    value: option.value as string,
                  });
                  if (val.includes("stack")) {
                    setOrderData("selected", "stacked", true);
                    if (val.includes("3")) {
                      setOrderData("selected", "stackCount", 3);
                    } else {
                      setOrderData(
                        produce((store) => {
                          store.selected.stackCount = 2;
                          store.selected.stacks.middleDetails = {
                            sku: undefined,
                            width: undefined,
                            height: undefined,
                            floater: undefined,
                            fillet: undefined,
                            mouldingWidth: undefined,
                            mouldingHeight: undefined,
                            stockLevel: undefined,
                            discontinued: undefined,
                            category: undefined,
                          };
                        })
                      );
                    }
                    setOrderData(
                      "selected",
                      "shipsFrom",
                      orderData.defaultShipsFrom
                    );
                  } else {
                    // If GF/PF/CORNER, overwrite the shipsFrom
                    if (
                      [
                        PT.GALLERYFRAME,
                        PT.PHOTOFRAME,
                        PT.CORNERSAMPLE,
                      ].includes(val as PT)
                    ) {
                      setOrderData("selected", "shipsFromPlant", "");
                    }
                    // ELSE - set the shipsFrom using the default plant (this plant is set in the details resource)
                    if (
                      ![
                        PT.GALLERYFRAME,
                        PT.PHOTOFRAME,
                        PT.CORNERSAMPLE,
                      ].includes(option.value as PT)
                    ) {
                      setOrderData(
                        "selected",
                        "shipsFrom",
                        orderData.defaultShipsFrom
                      );
                    }
                    setOrderData("selected", "stacked", false);
                    setOrderData("selected", "stackCount", undefined);
                  }
                }}
              />
              <Show when={orderData.selected.productType === PT.BOX}>
                <p class="text-xs text-roma-medium-grey">
                  &#x2022; Footage per box is approximate{" "}
                </p>
              </Show>
            </Match>
            <Match when={productInfo()?.status == "error"}>
              <InfoBox type="error">
                An error occurred while attempting to retrieve pricing
                information for this moulding - please call customer care at{" "}
                <a href="tel:18002632322" rel="nofollow" class="text-roma-blue">
                  1-800-263-2322
                </a>{" "}
                to proceed with this order. We apologize for the inconvenience.
              </InfoBox>
            </Match>
            <Match when={productInfo()?.status == "loading"}>
              <div class="flex justify-center items-center">
                <BaseLoader width={8} height={8} />
              </div>
            </Match>
          </Switch>
        </Show>
      </Show>
    </>
  );
};

// use internally only, this is just to satisfy some internal type errors
type ItemInventory = {
  name: string;
  plant: string;
  quantity: number;
};

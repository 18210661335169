import {
  Component,
  Show,
  Switch,
  Match,
  createResource,
  For,
  createMemo,
  createSignal,
  on,
  ErrorBoundary,
  Suspense,
  type JSX,
} from "solid-js";
import { produce, unwrap } from "solid-js/store";
import { SelectBox } from "~/components/inputs";
import {
  useItemOrderContext,
  useSiteContext,
  useSessionContext,
  useErrorContext,
} from "~/utils/contexts";
import { imageUrl } from "~/utils/products";
import { EventType } from "@solid-primitives/analytics";
import { ProductImage } from "~/components/product";
import { BaseLoader } from "~/components/utility";
import { stockThreshold, ThresholdCheckout } from "~/utils/threshold";
import { fetchAPI2 } from "~/services/roma-api/fetchAPI2";
import { retrieveProductInfo } from "~/services/roma-api/products/retrieveProductInfo";
import { checkError, simulateApiError } from "~/services/roma-api/errors";
import { useNavigate } from "@solidjs/router";
import { BaseSkeleton } from "~/components/utility";

//! import { facilityMapping } from "~/utils/misc";

// TODO - eliminate facilityMapping and use PLANTS
const facilityMapping: Record<string, string> = {
  BP01: "CAN",
  BP03: "LA",
  BP04: "NJ",
  BP05: "AT",
};

export const StackInventorySelector: Component = () => {
  const { session } = useSessionContext();
  const { addError } = useErrorContext();
  const { track } = useSiteContext();
  const { orderData, setOrderData } = useItemOrderContext();
  const [singlePlant, setSinglePlant] = createSignal<boolean>();
  const navigate = useNavigate();

  const listOfSkus = createMemo(() => {
    if (
      !orderData.selected.stacks.outsideDetails.sku ||
      !orderData.selected.stacks.insideDetails.sku
    ) {
      //  Return a false condition to block the resource from firing, display a "please select mouldings to continue msg"
      return [];
    }
    const arr = [
      orderData.selected.stacks.insideDetails.sku,
      orderData.selected.stacks.outsideDetails.sku,
    ];

    if (orderData.selected.stackCount == 2) {
      return arr;
    } else if (orderData.selected.stackCount == 3) {
      if (!orderData.selected.stacks.middleDetails.sku) {
        //  return a blocker again, display "please select all mouldings" msg
        return [];
      } else {
        arr.splice(1, 0, orderData.selected.stacks.middleDetails.sku);
        return arr;
      }
    }
  });

  type _InfoResponse = {
    Inventory: Record<string, number>;
    Plant: string;
    Plants: Record<string, string>;
    Pricing: {
      Plant: string;
    };
  };

  const [inventory] = createResource(
    () => [listOfSkus()],
    async ([list]) => {
      // if list is empty or does not exist:
      if (list?.length === 0 || !list) {
        return { dropdownOptions: [] };
      }

      if (Array.isArray(list) && list.length > 0) {
        try {
          const data = await Promise.all(
            list.map(async (sku: string) => {
              const repsonseData = await retrieveProductInfo(sku);

              return repsonseData;
            })
          );

          const obj = {
            skus: {} as Record<string, _InfoResponse["Inventory"]>,
            plants: [] as string[], // array of the plant names BP01 etc
            defaultPlant: "",
            dropdownOptions: [] as { label: JSX.Element; value: string }[],
          };

          const sorted = list.reduce((memo, item, index) => {
            if (memo.skus[item]) {
              return memo;
            } else {
              let inventoryDict = data[index].Inventory;
              memo.skus[item] = inventoryDict;
              for (const plant in inventoryDict) {
                if (memo.plants.includes(plant)) continue;
                memo.plants.push(plant);
              }
              return memo;
            }
          }, obj);

          sorted.defaultPlant = data[0].Pricing.Plant;

          // extra detail, put the default plant first - possibly display them in order later:
          const index = sorted.plants.indexOf(sorted.defaultPlant);
          if (index !== -1) {
            sorted.plants.splice(index, 1);
            sorted.plants.unshift(sorted.defaultPlant);
          }

          // formatting the dropdown list
          sorted.dropdownOptions = sorted.plants.map((item: string) => {
            return {
              label: (
                <div>
                  <span class="w-[30px] inline-block">
                    {facilityMapping[item]}
                  </span>
                  <span>
                    {item === sorted.defaultPlant ? (
                      <span class="ml-2">(Default)</span>
                    ) : null}
                  </span>
                </div>
              ),
              value: item,
            };
          });

          // * Note: Using '!' to bypass typescript errors below. This component
          // * would not have rendered without truthy checks on these values.
          setOrderData(
            produce((data) => {
              data.selected.stacks.outsideDetails.stockLevel = Math.round(
                sorted.skus[data.selected.stacks.outsideDetails.sku!][
                  sorted.defaultPlant
                ]
              );
              data.selected.stacks.insideDetails.stockLevel = Math.round(
                sorted.skus[data.selected.stacks.insideDetails.sku!][
                  sorted.defaultPlant
                ]
              );
              if (list.length == 3) {
                data.selected.stacks.middleDetails.stockLevel = Math.round(
                  sorted.skus[data.selected.stacks.middleDetails.sku!][
                    sorted.defaultPlant
                  ]
                );
              }
            })
          );

          if (sorted.plants.length === 1) {
            setSinglePlant(true);
          } else {
            setSinglePlant(false);
          }

          return sorted;
        } catch (error) {
          const err = checkError(error);
          if (import.meta.env.DEV) {
            console.log(
              "[StackInventorySelector.tsx]: Error caught in createResource: ",
              err
            );
          }
          addError(err, {
            severity: "warning",
            showDetails: true,
            title: "Stock Level Error",
            message:
              "An error occurred while retrieving stock levels. Please try again shortly. If this error persists, kindly contact customer support. We apologize for the inconvenience.",
            actions: [
              {
                label: "Contact Support",
                action: async () => navigate("/support"),
              },
            ],
          });
          throw err;
        }
      }
    },
    {
      initialValue: { dropdownOptions: [] },
      ssrLoadFrom: "initial",
    }
  );

  // ! Check stock levels of all stacks, report the worst case (block, backorder, optionalBackorder, allow)
  createMemo(
    on(
      () => [
        orderData.selected.stacks.insideDetails.stockLevel,
        orderData.selected.stacks.middleDetails.stockLevel,
        orderData.selected.stacks.outsideDetails.stockLevel,
      ],
      () => {
        const mouldings = [
          unwrap(orderData.selected.stacks.insideDetails),
          unwrap(orderData.selected.stacks.outsideDetails),
        ];

        if (orderData.selected.stackCount === 3) {
          mouldings.push(unwrap(orderData.selected.stacks.middleDetails));
        }

        // don't run the loop unless at least the first item's stock level has been gotten
        if (!mouldings[0].stockLevel && mouldings[0].stockLevel !== 0) {
          setOrderData("selected", "stackStockCheckoutStatus", undefined);
          return;
        }

        const stockResults = [];
        // loop through the stacks, determine the worst case stock level and go from there?
        for (let moulding of mouldings) {
          const stockStatus = stockThreshold(
            moulding.stockLevel!,
            moulding.category!,
            moulding.discontinued
          );
          // worst case - an item returns 'block' - in which case break out of the loop
          if (stockStatus.checkout === ThresholdCheckout.block) {
            setOrderData(
              "selected",
              "stackStockCheckoutStatus",
              stockStatus.checkout
            );
            return;
          }
          // otherwise push the checkout result to stockResults arr
          stockResults.push(stockStatus.checkout);
        }

        // Check & Set by priority - 'block' would have been handled in loop so we
        // are looking for 'backorder' and 'optionalBackorder' in that order:
        if (stockResults.includes(ThresholdCheckout.backorder)) {
          setOrderData(
            "selected",
            "stackStockCheckoutStatus",
            ThresholdCheckout.backorder
          );
          return;
        } else if (stockResults.includes(ThresholdCheckout.optionalBackorder)) {
          setOrderData(
            "selected",
            "stackStockCheckoutStatus",
            ThresholdCheckout.optionalBackorder
          );
          return;
        }
        // stock checks must have returned allow by this point..
        setOrderData(
          "selected",
          "stackStockCheckoutStatus",
          ThresholdCheckout.allow
        );
        return;
      }
    )
  );

  const InventoryLine: Component<{ sku: string }> = (props) => {
    const levels = createMemo(() => {
      if (
        !inventory() ||
        inventory.loading ||
        inventory.error ||
        // @ts-expect-error
        !inventory.latest?.skus
      ) {
        return {
          BP01: 0,
          BP03: 0,
          BP04: 0,
          BP05: 0,
        };
      } else {
        return {
          BP01: Math.round(inventory.latest?.skus?.[props?.sku]["BP01"]),
          BP03: Math.round(inventory.latest?.skus?.[props?.sku]["BP03"]),
          BP04: Math.round(inventory.latest?.skus?.[props?.sku]["BP04"]),
          BP05: Math.round(inventory.latest?.skus?.[props?.sku]["BP05"]),
        };
      }
    });

    return (
      <>
        {/* <div class="col-span-full border-t" /> */}
        <div class="flex  gap-2 items-center">
          <div class="w-8 h-8 shrink-0 grow-0 hidden sm:flex bg-white p-1 justify-center items-center  ">
            <ProductImage
              src={imageUrl(props.sku, "moulding", 1)}
              alt=""
              width={24}
              height={24}
              quality={100}
            />
          </div>
          <p class="font-bold sm:font-light">{props.sku}</p>
        </div>
        {/* BP01 - CAN */}
        <p
          class="text-xs sm:text-sm"
          classList={{
            "font-medium": orderData.selected.shipsFrom == "BP01",
            "text-gray-400": levels().BP01 === 0,
          }}
        >
          {levels().BP01} ft
        </p>
        {/* BP03 - LA */}
        <p
          class="text-xs sm:text-sm"
          classList={{
            "font-medium": orderData.selected.shipsFrom == "BP03",
            "text-gray-400": levels().BP03 === 0,
            invisible: singlePlant() === true,
          }}
        >
          {levels().BP03} ft
        </p>
        {/* BP04 - NJ */}
        <p
          class="text-xs sm:text-sm"
          classList={{
            "font-medium": orderData.selected.shipsFrom == "BP04",
            "text-gray-400": levels().BP04 === 0,
            invisible: singlePlant() === true,
          }}
        >
          {levels().BP04} ft
        </p>
        {/* BP05 - AT */}
        <p
          class="text-xs sm:text-sm"
          classList={{
            "font-medium": orderData.selected.shipsFrom == "BP05",
            "text-gray-400": levels().BP05 === 0,
            invisible: singlePlant() === true,
          }}
        >
          {levels().BP05} ft
        </p>
      </>
    );
  };

  return (
    <ErrorBoundary
      fallback={(error, reset) => {
        return (
          <div class="bg-red-100 rounded-md px-3 py-2 text-sm w-full">
            <p>Stock levels unavailable. Please try again later.</p>
          </div>
        );
      }}
    >
      <div class="flex flex-col gap-5">
        <Suspense fallback={<BaseSkeleton height={150} />}>
          <Show
            when={
              inventory.latest?.dropdownOptions &&
              inventory.latest?.dropdownOptions.length > 0
            }
          >
            <SelectBox
              label="Ships From"
              triggerClass="rounded-sm"
              options={inventory.latest?.plants.map((item: string) => {
                return {
                  label: (
                    <div>
                      <span class="w-[30px] inline-block">
                        {facilityMapping[item]}
                      </span>
                      <span>
                        {item === inventory.latest?.defaultPlant ? (
                          <span class="ml-2">(Default)</span>
                        ) : null}
                      </span>
                    </div>
                  ),
                  value: item,
                  // Tariff Logic - Block the option for US customers to ship from CA. Remove when resolved
                  disabled: item === "BP01",
                };
              })}
              loading={inventory.loading}
              disabled={!inventory.latest || singlePlant() === true}
              defaultValue={orderData.selected.shipsFrom}
              onChange={(option) => {
                setOrderData("selected", "shipsFrom", option.value as string);
                track(EventType.Event, {
                  category: "order",
                  action: "ships_from_stack",
                  value: option.value as string,
                });
                if (orderData.selected.stackCount === 3) {
                  setOrderData(
                    "selected",
                    "stacks",
                    "middleDetails",
                    "stockLevel",
                    Math.round(
                      inventory.latest?.skus[
                        orderData.selected.stacks.middleDetails.sku
                      ][option.value]
                    )
                  );
                }
                setOrderData(
                  produce((data) => {
                    data.selected.stacks.outsideDetails.stockLevel = Math.round(
                      inventory.latest?.skus[
                        data.selected.stacks.outsideDetails.sku
                      ][option.value]
                    );
                    data.selected.stacks.insideDetails.stockLevel = Math.round(
                      inventory.latest?.skus[
                        data.selected.stacks.insideDetails.sku
                      ][option.value]
                    );
                  })
                );
              }}
            />
          </Show>
          <div class="px-5 py-5 bg-roma-grey font-light">
            <Show
              when={inventory.latest?.skus}
              fallback={
                <Show
                  when={!inventory.loading}
                  fallback={
                    <div class="w-full flex justify-center items-center py-10">
                      <BaseLoader width={5} height={5} />
                    </div>
                  }
                >
                  <div class="col-span-full text-center py-10">
                    Please select all mouldings in the stack to see stock levels
                  </div>
                </Show>
              }
            >
              {/* Eventually use this switch/match to have different displays for CA/USA customers - CA customers will only see CA stock levels, grid looks empty */}
              <Switch>
                <Match when={true}>
                  <div class="grid grid-cols-stack-stock  items-center gap-y-2 pb-2">
                    <h4 class="font-bold text-lg mb-1">Stock Levels</h4>
                    <h4
                      class="font-bold text-base mb-1"
                      classList={{
                        "text-roma-blue":
                          orderData.selected.shipsFrom == "BP01",
                      }}
                    >
                      {facilityMapping["BP01"]}
                    </h4>
                    <h4
                      class="font-bold text-base mb-1"
                      classList={{
                        "text-roma-blue":
                          orderData.selected.shipsFrom == "BP03",
                        invisible: singlePlant() === true,
                      }}
                    >
                      {facilityMapping["BP03"]}
                    </h4>
                    <h4
                      class="font-bold text-base mb-1"
                      classList={{
                        "text-roma-blue":
                          orderData.selected.shipsFrom == "BP04",
                        invisible: singlePlant() === true,
                      }}
                    >
                      {facilityMapping["BP04"]}
                    </h4>
                    <h4
                      class="font-bold text-base mb-1"
                      classList={{
                        "text-roma-blue":
                          orderData.selected.shipsFrom == "BP05",
                        invisible: singlePlant() === true,
                      }}
                    >
                      {facilityMapping["BP05"]}
                    </h4>
                    <For each={listOfSkus()}>
                      {(item) => <InventoryLine sku={item} />}
                    </For>
                  </div>
                </Match>
                <Match when={false}>
                  <div class="grid grid-cols-stack-stock  items-center gap-y-2">
                    <h4 class="font-bold text-xl mb-1">Stock Levels</h4>
                    <h4
                      class="font-bold text-base mb-1"
                      classList={{
                        "text-roma-blue":
                          orderData.selected.shipsFrom == "BP01",
                      }}
                    >
                      {facilityMapping["BP01"]}
                    </h4>
                    <h4
                      class="font-bold text-base mb-1"
                      classList={{
                        "text-roma-blue":
                          orderData.selected.shipsFrom == "BP03",
                        invisible: singlePlant() === true,
                      }}
                    >
                      {facilityMapping["BP03"]}
                    </h4>
                    <h4
                      class="font-bold text-base mb-1"
                      classList={{
                        "text-roma-blue":
                          orderData.selected.shipsFrom == "BP04",
                        invisible: singlePlant() === true,
                      }}
                    >
                      {facilityMapping["BP04"]}
                    </h4>
                    <h4
                      class="font-bold text-base mb-1"
                      classList={{
                        "text-roma-blue":
                          orderData.selected.shipsFrom == "BP05",
                        invisible: singlePlant() === true,
                      }}
                    >
                      {facilityMapping["BP05"]}
                    </h4>
                    <For each={listOfSkus()}>
                      {(item) => <InventoryLine sku={item} />}
                    </For>
                  </div>
                </Match>
              </Switch>
            </Show>
          </div>
        </Suspense>
      </div>
    </ErrorBoundary>
  );
};
